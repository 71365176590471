<template>
  <div>
      
       <div class="contents">
      <div class="tableSearch">
          <div class="classObj"><el-button @click="createNew" type="primary" icon="el-icon-search">新建等级</el-button></div>
          <el-form :inline="true" ref="searchForm" :model="searchForm"  label-width="100px">
             <el-form-item label="等级名称" style="margin-bottom:0px">
              <el-input v-model="searchForm.name" clearable  style="width:190px"
                placeholder="请输入等级名称"></el-input>
            </el-form-item>
            <el-button @click="getTopSearch" type="primary" icon="el-icon-search">查询</el-button>
            <el-button @click="chongzhi">重置</el-button>
          </el-form>
      </div>
      <div>

    <div class="mainbox" style="padding: 10px">
            <el-table
            :data="levels"
            border
            :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
            >
                <el-table-column
                label="序号"
                type="index"
                >
                </el-table-column>
                <el-table-column
                label="排序"
                width="50"
                prop="sort"
                >
                </el-table-column>

                <el-table-column
                label="等级名称"
                prop="name"
                >
                </el-table-column>
                <el-table-column
                label="编号"
                prop="code"
                >
                </el-table-column>
                <el-table-column
                label="备注"
                prop="remark"
                >
                </el-table-column>
                <el-table-column
                width="100"
                label="操作"
                >
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="modify(scope.row)">修改</el-button>
                </template>
                </el-table-column>

            </el-table>
        </div> 
      
      </div>
    </div>

    <el-dialog title="等级信息"  :before-close="handleClose"  width="600px" :visible.sync="visiable">
      <el-form ref="formAdd" :model="formAdd" label-position="right" :rules="rules" label-width="120px"
        style="width:480px;">
        <el-form-item label="名称" prop="name">
          <el-input v-model="formAdd.name" autocomplete="off" maxlength="30" placeholder="请输入等级名称"></el-input>
        </el-form-item>

        <el-form-item label="编号" prop="code">
          <el-input v-model="formAdd.code" autocomplete="off" maxlength="30" placeholder="请输入编号"></el-input>
        </el-form-item>

        <el-form-item label="顺序（从小到大）" prop="sort">
          <el-input v-model="formAdd.sort" autocomplete="off" maxlength="30" placeholder="请输入数字"></el-input>
        </el-form-item>

        <el-form-item label="备注" prop="remark">
          <el-input v-model="formAdd.remark" autocomplete="off" maxlength="30" placeholder="请输入备注"></el-input>
        </el-form-item>


        <div class="midCss">
          <el-button type="primary" :loading="binding" @click="handleSubmit('formAdd')" size="medium">提交</el-button>
          <el-button @click="handleClose" size="medium">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getLevel, addOrUpdateLevel } from "@/api/level.js"

export default {
  name: 'Index',
  components: {  },
  data() {
    return {
      searchForm: {
        name: '',
      },
      formAdd: {
          id: null,
          name: '',
          code: '',
          sort: 0,
          remark: '',
      },
      total: 0,
      page: 1,
      pageSize: 20, 
      levels: [],     
      binding: false,
      visiable: false,
      role: "supplier",
      rules: {
        name: [{ required: true, message: '请输入等级名称', trigger: 'blur' }]
      }
    }
  },
  methods: {
      chongzhi() {
        this.searchForm = {
            name: ''
        }
        this.getTopSearch()
      },
    reset() {
        this.formAdd = {
            id: null,
            name: "",
          code: '',
          sort: 0,
          remark: '',
        }
    },
    createNew() {
        this.reset()
this.visiable = true
    },
    handleSubmit(formName) {
      const that = this;      
      that.$refs[formName].validate((valid) => {
        if (valid) {
            const param = {
                role: that.role,
                ...that.formAdd
            }
          that.binding = true
          addOrUpdateLevel(param).then(res => {
            that.binding= false
            console.log(res);
            if(res.code === 200) {
              that.$message.success(res.message)
              that.handleClose()
              that.getTopSearch()
            }else{
              that.$message.error(res.message)
            }
          })
        }
      })    
    },    
    modify(item) {        
        this.formAdd = {
            id: item.id,
            name: item.name,   
            code: item.code,
            sort: item.sort,
            remark: item.remark         
        }
        this.visiable = true
    },
    handleClose(){
      this.visiable = false
      this.reset()
    },
    getTopSearch(e){
      var that = this
      var datas = that.searchForm
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.orderByCreated = "DESC";
      datas.role = that.role
      getLevel(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.levels = res.data.rows
          that.total = res.data.totalRows
        }else{
          this.$message.error(res.message)
        }
      })
    },
    contactClick(row){
      this.$router.push({
        path: "/reboter/contact?id=" + row.botId
      })
    }
  },
  mounted(){
    this.getTopSearch()
  }
}
</script>

<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}

.el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
}

.cardList{
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: block;
  margin: 20px auto 0px auto;
  overflow: hidden;
}
@media screen and (max-width: 1338px){
  .cardList{
    width: 844px;
  }
}
@media screen and (min-width: 1338px){
  .cardList{
    width: 1266px;
  }
}
@media screen and (min-width: 1920px){
  .cardList{
    width: 1688px;
  }
}
.cardBox{
  float: left;
  width: 400px;
  overflow: hidden;
}
.cardItem{
  margin: 12px;
  display: block;
  background-color: #fff;
  padding: 20px 10px;
  border: 1px solid #eee;
  border-radius: 4px;
  position: relative;
}
.cardItem strong{
  display: block;
  text-align: center;
  line-height:44px;
  padding: 10px 0;
  margin-bottom: 10px;
}
.caozuobtns{
  display: block;
  text-align: center;
  padding: 10px 0;
}
.cardItem p{
  font-size: 12px;line-height:22px; text-align: center; color: #999;
}
.renSize{
  width: 120px; height: 120px; padding:20px; background-color: #f3f3f3;display: block; margin: 0 auto; border-radius: 100px; box-shadow: 0px 8px 0 #ccc;
}
.statusbox{
  position: absolute;
  left: 0; top: 0;
  font-size: 12px;
  display: block;
  overflow: hidden;
}

.statusbox .qidong{
  background-color: #07C160;
  color: #fff;
  font-weight: bold;
  padding: 5px 10px;
  display: block;
}

.statusbox .huise{
  background-color: #eee;
  color: #999;
  font-weight: bold;
  padding: 5px 10px;
  display: block;
}
.statusbox .yell{
  background-color: #ff7200;
  color: #fff;
  font-weight: bold;
  padding: 5px 10px;
  display: block;
}

.classObj{
  float:right;
  font-size: 12px;
  color: #999;
  cursor: pointer;
}
.shopLogo{
  display: block;
  overflow: hidden;
}
.shopLogo p{
  line-height:44px;
  display: block;
  text-align: center;
  height: 44px;
}
.shopol{
  width: 200px;
  height: 200px;
  display: block;
  overflow: hidden;
}
.shopboxs{
  width: 200px;
  height: 200px;
  display: block;
  border: 1px solid #eee;
  padding: 10px;
  margin: 0 auto 10px;
  cursor: pointer;
  background-color:#fff; 
  position:relative;
}

.yishixiao{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0,0,0,0.7);
  display: block;
  z-index: 999;
  text-align: center;
}
.yishixiao p{
  line-height:44px;
  padding-top: 60px;
  font-size: 16px;color: #fff;
}
.refeishi{
  width: 100px;
  height: 30px;
  border: 1px solid #eee;
  border-radius: 2px;
  color: #fff;
  line-height:30px;
  margin: 0 auto;
}
.buyBtn{
  display: inline-block;
  padding: 0 22px;
  font-size: 15px;
  line-height:32px;
  background-color: #ff7200;
  color: #fff;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
}
.buyimg{
  width: 200px;
  height: 177px;
  display: block;
  margin: 0 auto;
}
.buyAdmin{
  display: block;
  padding: 0 10px;
  overflow: hidden;
  text-align: center;
}
.buyAdminList{
  margin: 10px;
  background-color: #fff;
  padding: 10px;
  overflow: hidden;
}
.buyAdmin h2{
  color: #ff0000;
  font-size: 24px;display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  margin-top: -20px;
  margin-bottom: 10px;
}
.pload{
  line-height:24px;
  font-size: 12px;
}
.pload span{
  width: 300px;
  padding-left: 20px;
}
</style>